import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import useComponentVisible from "../../hooks/useComponentVisible"
import { basedStyles } from "../../styles"
import { Fa } from "../../utils"
import TimezonePicker from "../TimezonePicker"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`

const FaIcon = styled(Fa).attrs(() => ({
  icon: "globe",
  style: {
    fontSize: "1.35em",
  },
}))`
  position: absolute;
  top: 0.5em;
  right: 0.75em;

  color: ${p => p.theme.colors.secondary};

  ${basedStyles.interxEffect}
`

const ModalContainer = styled.div`
  min-width: 250px;

  position: absolute;
  top: 2.25em;
  right: 1em;

  background-color: ${p => p.theme.colors.primary};

  border: 1px solid ${p => p.theme.colors.secondary};
  border-radius: 2px;

  z-index: 1;

  padding: 0.5em 0.75em;
`

const EachPickerContainer = styled.div`
  margin-bottom: 0.5em;
`

const TimezoneEditionModal = ({ currentTimezone, onTimezoneChange }) => {
  const {
    ref: visibleRef,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)

  return (
    <Container ref={visibleRef}>
      <FaIcon onClick={() => setIsComponentVisible(prev => !prev)} />
      {isComponentVisible ? (
        <ModalContainer>
          <EachPickerContainer>
            <label>Time Zone</label>
            <TimezonePicker
              value={currentTimezone}
              onChange={onTimezoneChange}
            />
          </EachPickerContainer>
        </ModalContainer>
      ) : null}
    </Container>
  )
}

TimezoneEditionModal.propTypes = {
  currentTimezone: PropTypes.string.isRequired,
  onTimezoneChange: PropTypes.func,
}

TimezoneEditionModal.defaultProps = {
  onTimezoneChange: () => {},
}

export default TimezoneEditionModal

import moment from "moment-timezone"
import PropTypes from "prop-types"
import React from "react"
import styled, { css } from "styled-components"
import { Fa } from "../../utils"

// -- CONSTANTS
const talkFormatLabelColors = {
  "Virtual Only": "#d0f0fd",
}

const starColor = {
  normal: "#eee",
  selected: "orange",
  relevance: "#8cb3d9",
}

const themeLabelColors = {
  A: "#a479ef",
  D: "#367ff9",
  J: "#fcb301",
  L: "#f43261",
  M: "#fe6a40",
  N: "#e574bc",
  P: "#31c640",
  S: "#1e2d24",
}

// -- FUNCTIONS
const getColorOfTheme = theme =>
  Object.entries(themeLabelColors).find(
    ([k, _]) => k === theme?.match(/^([A-Z])/)?.[1]
  )?.[1] || "#000"

const getColorOfTalkFormat = talkFormat => talkFormatLabelColors[talkFormat]
const getTextColorOfTalkFormat = talkFormat => {
  if (["Keynote Event", "Special Event"].includes(talkFormat)) {
    return "#ffffff"
  }

  return "#222222"
}

const isoToTimezone = (dtStr, tz) =>
  moment
    .utc(dtStr)
    .tz(tz)
    .format("MMM DD, h:mm A")

// -- COMPONENTS
const Container = styled.div`
  flex: 1;
  display: flex;

  border: 1px solid #ddd;
  border-radius: 5px;

  border-left: 5px solid ${p => p.accent || p.theme.colors.secondary};

  padding: 5px 0;
  margin-bottom: 8px;
  margin-right: 2px;

  cursor: pointer;

  /* truncate text */
  overflow: hidden;
  /* truncate text */
`

const FavIconContainer = styled.div`
  width: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledFavIcon = styled(Fa).attrs(() => ({
  icon: "star",
}))`
  cursor: pointer;
  color: ${props => (props.checked ? starColor.selected : starColor.normal)};
  font-size: 1.55rem !important;
  /* refer to the id of svg  */
  /* ${props =>
    props.relevance &&
    !props.checked &&
    css`
      path {
        fill: url(#${`lgrad-${props.relevance}`});
      }
    `} */
`

const SpinIcon = styled(Fa).attrs(() => ({
  icon: "sync",
  spin: true,
}))`
  color: ${p => p.theme.colors.secondary};
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 7px 0;

  /* truncate text */
  overflow: hidden;
  /* truncate text */
`

const TitleText = styled.p`
  margin: 0;
  font-size: 0.95em;

  /* to distinguise title out of other texts */
  font-family: nunito;

  /* truncate text */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* truncate text */
`

const NameText = styled(TitleText)`
  font-size: 0.675em;
  font-family: "Open Sans";
  font-style: italic;
`

const TimeText = styled(NameText)`
  font-style: normal;
`

const TrackBox = styled.div`
  display: flex;
  align-items: center;

  font-size: 0.8em;
  border: 1px solid ${p => p.accent || p.theme.colors.secondary};
  border-radius: 5px;
  background-color: ${p => `${p.accent}1A`};
  width: fit-content;

  padding: 1.5px 3px;
  white-space: nowrap;
`

const TalkFormatBox = styled(TrackBox)`
  border-radius: 0;
  background-color: ${p => `${p.accent}`};
`

const ThemeBox = styled(TrackBox)`
  /* truncate text */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 98%;
  /* truncate text */
`

const TalkFormatTrackText = styled.span`
  color: ${p => p.accent};
  font-size: 0.675em;
  font-weight: bold;
`

const ThemeText = styled(TalkFormatTrackText)`
  font-weight: unset;

  /* truncate text */
  text-overflow: ellipsis;
  overflow: hidden;
  /* truncate text */
`

// -- MAIN
const AbstractListItem = ({ data, handleClickVote, isLiked, timezone }) => {
  // console.log(data)

  if (!data) {
    return (
      <Container
        css={`
          justify-content: center;
          align-items: center;
        `}
      >
        <SpinIcon />
      </Container>
    )
  }

  return (
    <Container accent={getColorOfTheme(data?.presentation_number)}>
      <FavIconContainer
        onClick={e => {
          handleClickVote(data?.submission_id)
          // block this because if the item has
          // description this will propagate
          // through invoke showing description
          e.stopPropagation()
        }}
      >
        <StyledFavIcon checked={isLiked} />
      </FavIconContainer>
      <ContentContainer
        css={`
          p {
            margin: 0;
          }
        `}
      >
        <div
          css={`
            line-height: 1em;
          `}
        >
          <TitleText>{data?.title}</TitleText>
          <div
            css={`
              margin-top: 3px;
            `}
          >
            {data?.authors ? <NameText>{data?.authors}</NameText> : null}
            {data?.starttime ? (
              <TimeText>
                <Fa icon={["far", "clock"]} />
                {` ${isoToTimezone(data?.starttime, timezone)} `}
              </TimeText>
            ) : null}
          </div>
        </div>

        <div
          css={`
            display: flex;
            flex-direction: row;

            & > div {
              :not(:last-child) {
                margin-right: 4px;
              }
            }
          `}
        >
          <ThemeBox accent={getColorOfTheme(data?.presentation_number)}>
            <ThemeText accent={getColorOfTheme(data?.presentation_number)}>
              {data?.presentation_number}
            </ThemeText>
          </ThemeBox>
          <TalkFormatBox accent={getColorOfTalkFormat(data?.location)}>
            <TalkFormatTrackText
              accent={getTextColorOfTalkFormat(data?.location)}
            >
              {data?.location}
            </TalkFormatTrackText>
          </TalkFormatBox>
        </div>
      </ContentContainer>
    </Container>
  )
}

AbstractListItem.propTypes = {
  data: PropTypes.shape({
    submission_id: PropTypes.string,
    title: PropTypes.string,
    talk_format: PropTypes.string,
    theme: PropTypes.string,
    track: PropTypes.string,
    fullname: PropTypes.string,
    authors: PropTypes.string,
    institution: PropTypes.string,
    starttime: PropTypes.string,
    endtime: PropTypes.string,
    youtube_url: PropTypes.string,
    zoom_url: PropTypes.string,
  }),
  handleClickVote: PropTypes.func,
  isLiked: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
}

AbstractListItem.defaultProps = {
  data: null,
  handleClickVote: () => {},
  isLiked: false,
}

export default AbstractListItem
